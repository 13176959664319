<template>
  <BCard>
    <BOverlay
      :show="cargando_cursos"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>
        <!-- TITULO Y SUBTITULO -->
        <BCol cols="6" class="mb-3">
          <b-alert
            :show="true"
            variant="primary"
            class="pt-25 pb-25 pl-50 pr-250"
          >
            Haz click en la hora de un día, para cargar el formulario.
          </b-alert>
        </BCol>

        <!-- CURSO -->
        <BCol cols="6" class="mb-3">

          <v-select
            v-model="id_curso_selected"
            :placeholder="optionsCursosHorario.length === 0
              ? 'No existen cursos disponibles...'
              : 'Seleccione el/los cursos...'"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :reduce="option => option.id_cursos"
            :options="optionsCursosHorario"
            :disabled="optionsCursosHorario.length === 0"
            :clearable="false"
            :closeOnSelect="false"
          />

        </BCol>

        <!-- HORARIO -->
        <BCol cols="12">
          <horarioForm
            v-if="show_horario_form && id_curso_selected !== null"
            :id_curso_selected.sync="id_curso_selected"
            @processForm="agregar"
          />
        </BCol>
      </BRow>
    </BOverlay>
  </BCard>
</template>

<script>
// ETIQUETAS
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BOverlay,
  BAlert,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import store from '@/store/index'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// COMPONENTES HIJOS
import horarioForm from './components/HorariosForm.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BCard,
    BTable,
    BOverlay,
    vSelect,
    BAlert,

    // COMPONENTES HIJOS
    horarioForm,
  },
  data() {
    return {
      cargando_cursos: true,
      show_horario_form: true,
      id_curso_selected: null,
      id_persona_rol: null,
      optionsCursosHorario: [],
      data: {
        curso: '',
        profesorJefe: '',
        estado: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      getAsignaturasCurso: 'asignaturas/getAsignaturasCurso',
      user: 'auth/user',
    }),
  },
  watch: {
    getCursosPersonaRol(val) {
      this.cargando_cursos = false
    },
    user (val) {
      this.id_persona_rol = null
      this.id_persona_rol = val.id_persona_rol
      this.id_establecimiento = null
      this.id_establecimiento = val.id_establecimiento
      this.cargando_cursos = true
      this.cargarCursosHorario(this.id_persona_rol)
    },
  },
  methods: {
    ...mapActions({
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      attempt: 'auth/attempt',
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
      createHorario: 'horarios/addHorarios'
    }),
    cargarCursosHorario(id_persona_rol) {
      this.cargando_cursos = true
      this.fetchCursosPersonaRol(id_persona_rol).then(() => {
        this.id_curso_selected = null
        this.optionsCursosHorario = []
        this.id_curso_selected = this.getCursosPersonaRol[0]['id']
        this.getCursosPersonaRol.forEach(curso => {
          const nombre = curso.nombre+' '+curso.letra
          this.optionsCursosHorario.push({
            id_cursos: curso.id,
            title: nombre,
            // id_tipo_ensenanza: curso.id_tipo_ensenanza,
          })
        })
      })
    },
    agregar(horario) {
      this.createHorario(horario).then(() => {
        const errorHorarios = store.state.horarios
        const errorMessage = errorHorarios.errorMessage.errors
        if (!errorHorarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Horario creado 👍',
              icon: 'CheckIcon',
              text: `El horario "${horario.nombre}" fue creado con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'horarios',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
